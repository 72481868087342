<template>
  <div>
    <img
      src="../../../assets/loader/imagens-e-gifs-de-loading-8.gif"
      alt=""
      id="loader"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeMount() {
    this.loarder = true;
  },
};
</script>

<style>
#loader {
  width: 5rem;
  margin-left: 6rem;
}
.body {
  opacity: 100%;
}
</style>