<template>
  <div class="container-fluid">
    <div style="margin-bottom: 15px">
      <router-link to="metodologia">
        <button class="btn btn-outline-secondary">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </router-link>
    </div>
    <div>
      <table class="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Bot</th>
            <th scope="col">Sender</th>
            <th scope="col">Token Curso</th>
            <th scope="col">Qt Cursos</th>
            <th scope="col">Cliente</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in this.list_projetcts" :key="project.id">
            <th scope="row">{{ project.id }}</th>
            <td>{{ project.name }}</td>
            <td>{{ project.bot_name }}</td>
            <td>{{ project.sender_phone }}</td>
            <td>{{ project.sender_phone }}</td>
            <td>{{ project.count_of_courses }}</td>
            <td>{{ project.client_name }}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Opções
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#">Ver mais</a>
                  <a class="dropdown-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions" href="#">Ir para Curos</a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
    
<script>
import { http } from "../../http";

export default {
  name: "Projetos",
  data() {
    return {
      project_filter: null,
      list_projetcts: null,
    };
  },
  mounted() {
    this.list_all_projects();
  },
  methods: {
    list_all_projects: function () {
      var payload = {
        project_filter: null,
      };
      http
        .post("list_all_projects/", payload)
        .then((res) => this.list_projetcts = res.data)
    },
  },
};
</script>    

<style>
</style>
    