import { http } from "../http"
import { createRouter, createWebHashHistory } from 'vue-router'
import Chat from "../components/chat/Chat.vue"
import CommandTree from "../components/command-tree/CommandTree.vue"
import TreeList from "../components/command-tree/TreeList.vue"
import Cadastro from "../components/cadastro/Cadastro.vue"
import Enrolments from "../components/Enrolments.vue"
import Login from "../components/auth/Login.vue"
import Client from "../components/client/Client.vue"
import Metodologia from "../components/metodologia/metodologia.vue"
import Projetos from "../components/metodologia/projetos.vue"
import CuratedList from "../components/lms/CuratedList.vue"
import EnrollmentsInformation from "../components/customer-success/EnrollmentsInformation.vue"
import PushBatchesInformation from "../components/customer-success/PushBatchesInformation.vue"
import PushBatch from "../components/customer-success/PushBatch.vue"
import MessagesInformation from "../components/customer-success/MessagesInformation.vue"
import { useCookies } from "vue3-cookies"
const modelCookies = useCookies()
var routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat
  },
  {
    path: '/tree/:id',
    name: 'tree',
    component: CommandTree,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/tree-list',
    name: 'tree-list',
    component: TreeList,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: Cadastro,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/enrolments',
    name: 'enrolments',
    component: Enrolments,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/client',
    name: 'client',
    component: Client,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/metodologia',
    name: 'metodologia',
    component: Metodologia,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/projetos',
    name: 'projetos',
    component: Projetos,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/curated-list',
    name: 'curated-list',
    component: CuratedList,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/enrollments-information',
    name: 'enrollments-information',
    component: EnrollmentsInformation,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/push-batches-information',
    name: 'push-batches-information',
    component: PushBatchesInformation,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/push-batch/:id',
    name: 'push-batch',
    component: PushBatch,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
  {
    path: '/messages-information',
    name: 'messages-information',
    component: MessagesInformation,
    meta: { requireAuth: true, grantAll: false, grants: ['true'] }
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    var permission = modelCookies.cookies.get("set_user_info");
    if (permission == 'true') {
      return next()
    } else {
      next({ name: 'chat' });
    }
  } else {
    next();
  }
});

export default router
