<template>
<div class="container">
  

  
    <div class=" container-fluid bv-example-row">
      
        <div class="row text-center">
            <div class="card" title="Controle de Projetos e Cursos" sub-title="Lys Academy">
              <div class="card-header bg-gradient-info text-light">
                <h4><i class="bi bi-journal-check"></i> Composição de Cursos e Conteúdos</h4>

              </div>
              <div class="card-body">
                <div class="row">
                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <router-link to="/projetos">
                                <div class="card border-left-info shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                    Projetos
                                                </div>
                                                <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                    {{ this.data_controls.count_companys }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>

                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                                Cursos
                                            </div>
                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_courses }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                                Lessons
                                            </div>
                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_lessons }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                                Questions
                                            </div>
                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_questions }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
        <!-- Controle de Bot e Processos -->

        <div class="row text-center">
            <div class="card" title="Controle de Bot e Processos" sub-title="Lys Academy">
              <div class="card-header bg-gradient-primary text-light">
                <h4><i class="bi bi-robot"></i> Configuração de Bots</h4>
              </div>

              <div class="card-body">
                <div class="row">
                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                                Bots
                                            </div>
                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_bots }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Cad. Automatizado
                                            </div>

                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_subscription }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <router-link to="/tree-list">
                                <div class="card border-left-primary shadow h-100 py-2">
                                    <div class="card-body">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col mr-2">
                                                <div class="
                                text-xs
                                font-weight-bold
                                text-warning text-uppercase
                                mb-1
                            ">
                                                    Árvore de Comandos
                                                </div>
                                                <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                    {{ this.data_controls.count_commands }}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <i class="fas fa-comments fa-2x text-gray-300"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>

                    <div class="col">
                        <!-- Pending Requests Card Example -->
                        <div class="mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="
                            text-xs
                            font-weight-bold
                            text-warning text-uppercase
                            mb-1
                          ">
                                                Firewall
                                            </div>
                                            <div v-if="data_controls" class="h5 mb-0 font-weight-bold text-gray-800">
                                                {{ this.data_controls.count_commands }}
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    http
} from "../../http";

export default {
    name: "Metodologia",
    data() {
        return {
            testename: "testename",
            data_controls: null,
        };
    },
    mounted() {
        this.get_data_controls();
    },
    methods: {
        get_data_controls: function () {
            var payload = {
                current_page: this.current_page,
            };
            http
                .post("count_data_control/", payload)
                .then((res) => this.data_controls = res.data)
        },
    },
};
</script>

<style>
.card{
    padding: 0;
    margin-top: 5%;
    margin-bottom: 2%;
}
.col{
  align-items: center;
  margin-top: 2%;
}

</style>
