<template>
    <div class="container-fluid">
    <h2 class="centralizado">{{ title }}</h2>
    
    <input
      class="form-control bg-light border-0 ms"
      aria-label="Search"
      aria-describedby="basic-addon2"
      type="search"
      name="filter"
      id="filter"
      placeholder="Buscar curso..."
      v-on:input="filter = $event.target.value"
      style="margin-bottom: 1rem;"
    />

    <!-- DataTales Example -->
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Listagem de Cursos</h6>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Status</th>
                <th>Few Pushs</th>
                <th>Flow Execution</th>
                <th>ChatBot Type</th>
                <th>Opções</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="course of coursesWithFilter" v-bind:key="course" >
                <th scope="row">{{ course.id }}</th>
                <td>{{ course.name }}</td>
                <td>
                    <a v-if="course.is_active == true" class="btn btn-success btn-circle btn-sm"><i class="fas fa-check"></i> </a>
                    <a v-if="course.is_active == false" class="btn btn-danger btn-circle btn-sm"> <i class="fas fa-times"></i> </a>
                </td>
                <td>
                    <a v-if="course.few_pushs == true" class="btn btn-success btn-circle btn-sm"><i class="fas fa-check"></i> </a>
                    <a v-if="course.few_pushs== false" class="btn btn-danger btn-circle btn-sm"> <i class="fas fa-times"></i> </a>
                </td>
                <td>{{ course.flow_execution }}</td>
                <td>{{ course.chatbot_type }}</td>
                <td>
                  <div class="dropdown no-arrow">
                      <a class="dropdown-toggle btn btn-primary btn-icon-spli" href="#" role="button" id="dropdownMenuLink"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i class="fas fa-wrench fa-sm fa-fw text-black-400"></i>
                      </a>
                    <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink">
                        <div class="dropdown-header">Opções Para os Cursos:</div>
                         <router-link>  <a class="dropdown-item">Editar</a></router-link>
                        <a class="dropdown-item" href="#">Inscritos</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item " href="#">Excluir</a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Painel Enrolements",
      enrolments: [],
      filter: "",
    };
  },

  computed: {
    coursesWithFilter() {
      if (this.filter) {
        let exp = new RegExp(this.filter.trim(), "i");
        return this.enrolments.filter((foto) => exp.test(foto.name));
      } else {
        return this.enrolments;
      }
    },
  },

  created() {
    this.$http
      .get(
        "http://localhost:8000/api/v1/webhook_get_course_for_painel"
      )
      .then((res) => res.json())
      .then(
        (enrolments) => (this.enrolments = enrolments),
        (err) => console.log(err)
      );
  },
};
</script>
<style>
.centralizado {
  text-align: center;
}
.filter {
  display: block;
  width: 100%;
  
  
}
</style>