<template>
<div class="container p-3 my-3 border shadow">
    <h3 v-if="dataMessageTemplate.title != null" >{{ dataMessageTemplate.title }}</h3>

    <div class="progress">
        <div :class="['progress-bar', this.returnProgressBarClass()]" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <br />

    <div v-if="this.showAlert & this.alertMessage.type == 'danger'" class="alert alert-danger alert-dismissible fade show" role="alert">
        {{ this.alertMessage.message }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div v-if="this.showAlert & this.alertMessage.type == 'success'" class="alert alert-success alert-dismissible fade show" role="alert">
        {{ this.alertMessage.message }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <form>
        <div class="form" v-if="currentStep == 1">
            <div class="row">
                <div class="col-md-6 mb-3">
                    <label for="nameMessageTemplate">Nome</label>
                    <input type="text" class="form-control" id="nameMessageTemplate" placeholder="Nome" v-model="name" required />
                </div>
                <div class="col-md-6 mb-3">
                    <label for="variableMessageTemplate">Variable</label>
                    <input type="text" class="form-control" id="variableMessageTemplate" placeholder="Variáveis" v-model="variable" required />
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3">
                    <label class="my-1 mr-2" for="providerMessageTemplate">Company</label>
                    <select required class="custom-select my-1 mr-sm-2" id="providerMessageTemplate" v-model="companyName">
                        <option value="" selected> - </option>
                        <option v-for="company in allCompanies" v-bind:key="company.id" :value="company.name">{{ company.name }}</option>
                    </select>
                </div>
                <div class="col-md-4 mb-3">
                    <label class="my-1 mr-2" for="typeMessageTemplate">Tipo da Mensagem</label>
                    <select required class="custom-select my-1 mr-sm-2" id="typeMessageTemplate" v-model="messageType">
                        <option value="" selected>--- ESCOLHER ---</option>
                        <option value="text">Texto</option>
                        <option value="image">Imagem</option>
                        <option value="video">Vídeo</option>
                        <option value="audio">Áudio</option>
                        <option value="document">Documento</option>
                    </select>
                </div>
                <div class="col-md-4 mb-3">
                    <label class="my-1 mr-2" for="providerMessageTemplate">Provedor</label>
                    <select required class="custom-select my-1 mr-sm-2" id="providerMessageTemplate" v-model="provider">
                        <option value="" selected>--- ESCOLHER ---</option>
                        <option value="WHATSAPP">Whatsapp</option>
                        <option value="TEAMS">Teams</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form" v-if="currentStep == 2">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="textContentMessageTemplate">Conteúdo da Mensagem</label>
                    <textarea class="form-control" id="textContentMessageTemplate" placeholder="Conteúdo da Mensagem" rows="3" v-model="textContent"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-3">
                    <label for="buttonsMessageTemplate">Botões</label>
                    <input type="text" class="form-control" id="buttonsMessageTemplate" placeholder="Botão 01; Botão 02; Botão n" v-model="buttons" />
                </div>
            </div>
        </div>
        <div class="col-md-6" v-if="currentStep == 3 && messageType != 'text'">
            <div class="form-group">
                <label class="my-1 mr-2" for="typeFileMessageTemplate">Tipo do Arquivo</label>
                <select required class="custom-select my-1 mr-sm-2" id="typeFileMessageTemplate" v-model="typeFile" v-on:change="changeTypeFile()">
                    <option value="" selected>--- ESCOLHER ---</option>
                    <option value="UPLOAD">Do meu Computador</option>
                    <option value="URL">URL do Arquivo</option>
                </select>
            </div>
            <div class="form-group" v-if="this.typeFile == 'UPLOAD'">
                <label>Conteúdo de Mídia</label>
                <div class="custom-file">
                    <input type="file" class="custom-file-input" id="fileContentMessageTemplate" v-on:change="file = $event.target.files[0]" />
                    <label class="custom-file-label" for="fileContentMessageTemplate">{{
              this.file != null
                ? this.file.name + " - " + this.formatBytes(this.file.size)
                : "Escolher o arquivo"
            }}</label>
                </div>
            </div>
            <div class="form-group" v-if="this.typeFile == 'URL'">
                <label for="urlContentMessageTemplate">Insira a URL da mídia</label>
                <input type="text" class="form-control" id="urlContentMessageTemplate" placeholder="https://..." v-model="urlContent" />
            </div>
        </div>
        <div class="form-row" v-if="currentStep == 4">
            <div class="col-md-4 mb-3">
                <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="isPreApprovedMessageTemplate" v-model="isPreApproved" />
                    <label class="custom-control-label" for="isPreApprovedMessageTemplate">É pré-aprovado?</label>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="isButtonMessageTemplate" v-model="isButtonMessage" />
                    <label class="custom-control-label" for="isButtonMessageTemplate">Tem botão?</label>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="custom-control custom-checkbox mr-sm-2">
                    <input type="checkbox" class="custom-control-input" id="isDefaultContentMessageTemplate" v-model="isDefaultContent" />
                    <label class="custom-control-label" for="isDefaultContentMessageTemplate">Default Content</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-auto mr-auto">
                <button v-on:click="previousOrNextStep('previous')" v-if="currentStep > 1" class="btn btn-secondary" type="button">
                    <i class="fs-4 mb-3 bi bi-arrow-left-circle-fill"></i> Voltar
                </button>
            </div>
            <div class="col-auto">
                <button v-if="currentStep < maxStep" v-on:click="previousOrNextStep('next')" type="button" class="btn btn-primary">
                    <i class="fs-4 mb-3 bi bi-arrow-right-circle-fill"></i> Próximo
                </button>
                <button v-else class="btn btn-primary" type="button" v-on:click="saveMessageTemaplate">
                    <i class="fs-4 mb-3 bi bi-arrow-right-circle-fill"></i> Cadastrar
                </button>
            </div>
        </div>
    </form>
</div>
</template>

<script>
import {
    http_file,
    http
} from '../../../http';
export default {
    name: "MessageTemplate",
    props: ["dataMessageTemplate"],
    data() {
        return {
            currentStep: 1,
            maxStep: 4,
            name: "",
            messageType: "text",
            provider: "WHATSAPP",
            variable: "default",
            textContent: "",
            buttons: "",
            file: null,
            maxFileSize: 16, // MB
            urlContent: null,
            isPreApproved: false,
            isButtonMessage: false,
            isDefaultContent: true,
            typeFile: "",
            showAlert: false,
            alertMessage: {
                message: null,
                type: "danger"
            },
            error: false,
            newMessageTemplate: null,
            allCompanies: [],
            companyName: ""
        };
    },
    mounted() {
        this.listAllCompanies();
    },
    methods: {
        previousOrNextStep(previousOrNext) {
            if (previousOrNext == "next") {
                var validate = this.validateFields();
                if (validate == false) {
                    return;
                }
                if (this.currentStep == 2 && this.messageType == "text") {
                    this.currentStep++;
                }
                if (this.currentStep < this.maxStep) {
                    this.currentStep++;
                    this.showAlert = false;
                }
            } else {
                // Next
                if (this.currentStep == 4 && this.messageType == "text") {
                    this.currentStep--;
                }
                if (this.currentStep > 1) {
                    this.currentStep--;
                }
            }
        },
        validateFields() {
            if (this.currentStep == 1) {
                if (this.name == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Nome é obrigatório";
                    return false;
                }
                if (this.companyName == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Company é obrigatório";
                    return false;
                }
                if (this.messageType == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Tipo da Mensagem é obrigatório";
                    return false;
                }
                if (this.variable == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Variáveis é obrigatório";
                    return false;
                }
                if (this.provider == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Provedor é obrigatório";
                    return false;
                }
            }
            if (this.currentStep == 2) {
                if (this.textContent == "" && this.messageType == "text") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Conteúdo da Mensagem é obrigatório";
                    return false;
                }
            }
            if (this.currentStep == 3) {
                if (this.typeFile == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "O campo Tipo do Arquivo é obrigatório";
                    return false;
                }
                if (this.file == null && this.urlContent == "") {
                    this.showAlert = true;
                    this.alertMessage.message = "Escolha um arquivo ou informe a URL do conteúdo";
                    return false;
                }
                if (
                    this.file != null &&
                    this.file.size > this.maxFileSize * 1024 * 1024 &&
                    this.provider == "WHATSAPP"
                ) {
                    this.showAlert = true;
                    this.alertMessage.message =
                        "O tamanho do arquivo não pode ser maior que " +
                        this.formatBytes(this.maxFileSize * 1024 * 1024);
                    return false;
                }
            }
        },
        changeTypeFile() {
            this.file = null;
            this.urlContent = "";
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes";
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
        },
        returnProgressBarClass() {
            if (this.currentStep == 1) {
                return "progress-bar-width-25";
            } else if (this.currentStep == 2) {
                return "progress-bar-width-50";
            } else if (this.currentStep == 3) {
                return "progress-bar-width-75";
            } else if (this.currentStep == 4) {
                return "progress-bar-width-100";
            }
        },
        generateUrlFile: async function () {
            var content_url = null
            let formData = new FormData();
            formData.append("uploda_file", this.file);
            formData.append("type_midia", this.messageType);
            await http_file
                .post("upload_file_b2", formData)
                .then((res) => res.data)
                .then((responseURL) => {
                        this.urlContent = responseURL.url_file
                    },

                )
                .finally(() =>
                    (console.log("fim upload file")), );
            return this.urlContent
        },
        saveMessageTemaplate: async function () {
            if (this.typeFile == "UPLOAD") {
                this.urlContent = await this.generateUrlFile()
            }

            var payload = {
                name: this.companyName + ": " + this.name,
                button: this.isButtonMessage,
                pre_approved: this.isPreApproved,
                variable: this.variable,
                default: this.isDefaultContent,
                message_type: this.messageType,
                content_url: this.urlContent,
                content_text: this.textContent,
                button_text: this.buttons,
                tag: this.dataMessageTemplate.tag,
            }
            http
                .post('painel/messaging/save_message_template/', payload)
                .catch((error) => {
                    this.error = true
                })
                .then((res) => (
                    this.newMessageTemplate = res.data,
                    this.$emit("newMessageTemplate", {
                        id: this.newMessageTemplate.id,
                        name: this.newMessageTemplate.name,
                    })))
                .finally(() => {
                    if (this.error == false) {
                        this.alertMessage.message = "Cadastrado com Sucesso"
                        this.alertMessage.type = "success"
                        this.showAlert = true
                        this.name = null
                        this.isButtonMessage = null
                        this.isPreApproved = null
                        this.variable = null
                        this.isDefaultContent = null
                        this.messageType = null
                        this.urlContent = null
                        this.textContent = null
                        this.button = null
                        this.currentStep = 1
                    } else if (this.error == true) {
                        this.alertMessage.message = "Ops: erro ao cadastrar template"
                        this.alertMessage.type = "danger"
                        this.showAlert = true
                    }
                });

        },
        listAllCompanies() {
            var payload = {
                project_filter: null,
            };
            http
                .post("list_all_projects/", payload)
                .then((response) => this.allCompanies = response.data)
        },
    },
};
</script>

<style scoped>
.button-previous-and-next {
    cursor: pointer;
}

.progress-bar-width-25 {
    width: 25%;
}

.progress-bar-width-50 {
    width: 50%;
}

.progress-bar-width-75 {
    width: 75%;
}

.progress-bar-width-100 {
    width: 100%;
}
</style>
