<template>
  <div>
    <span class="date-time">
      <a
        @click="changeMessagePriority(message.id)"
        v-if="message.direction == 'received'"
        style="cursor: pointer"
      >
        <i
          v-if="message.priority != 0"
          class="bi bi-1-circle-fill text-danger fs-6"
        ></i>
        <i v-else class="bi bi-circle text-secondary fs-6"></i>
      </a>

      &nbsp;
      {{ getFormattedDate(message.created) }}
      &nbsp;
      <a>
        <i
          v-if="message.status == 'queued' || message.status == 'failed'"
          class="bi bi-exclamation-circle text-danger fs-6"
        ></i>
        <i v-else class="bi bi-check-circle text-success fs-6"></i>
      </a>
    </span>
  </div>
</template>
<script>
import { http, headers } from "../../../http";
export default {
  props: ["message"],

  methods: {
    getFormattedDate(dataTime) {
      var dataTimeSplit = dataTime.split("T");
      var dataSplit = dataTimeSplit[0].split("-");
      var hourSplit = dataTimeSplit[1].split(":");
      var year = dataSplit[0];
      var month = dataSplit[1];
      var day = dataSplit[2];
      var hours = hourSplit[0];
      var minutes = hourSplit[1];
      return day + "/" + month + "/" + year + " - " + hours + ":" + minutes;
    },
    changeMessagePriority(messageId) {
      var payload = {
        message_id: messageId,
      };
      http
        .put("change-message-priority/", payload, headers)
        .then((response) => response.data);

      if (this.message.priority == 0) {
        this.message.priority = 2;
      } else {
        this.message.priority = 0;
      }
    },
  },
};
</script>
<style scoped>
.date-time {
  font-size: 11px;
  color: #a09999;
}
</style>
