<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  Total de Mensagens
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ heig_priority + medium_priority }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="primary"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-danger text-uppercase
                    mb-1
                  "
                >
                  Prioridade Alta
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ heig_priority }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="danger"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-warning text-uppercase
                    mb-1
                  "
                >
                  Prioridade Média
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ medium_priority }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="warning"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  Talk Tutor
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ enrolments_talk_tutor }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="success"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { http, headers } from "../../../http";

export default {
  props: [
    "total_message",
    "heig_priority",
    "medium_priority",
    "enrolments_talk_tutor",
  ],
  data() {
    return {};
  },
  created() {},

  methods: {},
};
</script>
<style></style>

