<script>
import { http, http_file } from "../../http";
import Loader from "../shared/painel/Loader.vue";
import CuratedMessageCards from "./CuratedMessageCards.vue";

export default {
  data() {
    return {
      indexCuration: null,
      allCompanies: [],
      currentPage: 1,
      totalPages: 1,
      companyId: "",
      companyName: "",
      showImportCsv: false,
      showLoader: false,
      file: null,
      importReturn: {
        number_of_imported_rows: 0,
        number_of_rows_with_error: 0,
        cmd_with_error: [],
      },
      indexCurationCount: 0,
      notUnderstandCount: 0,
      preIndexCount: 0,
      filterUnderstand: null,
      filterType: null,      
      catch_alert: {
        type: null,
        message: null,
      },
      curated: {
        indexCurationId: null,
        curatedCompanyId: null,
        curatedSentence: null,
        understand: null,
        type_relations: null,
        custon_commands: null,
        internal_commands: null,
        command_id: null,
      },
      allCommands: [],
      showModal: false,
    };
  },
  components: {
    Loader,
    "curated-message-cards": CuratedMessageCards,
  },
  mounted() {
    this.listAllCompanies();
  },
  methods: {
    listAllCompanies() {
      var payload = {
        project_filter: null,
      };
      http
        .post("list_all_projects/", payload)
        .then((response) => (this.allCompanies = response.data));
    },
    getAllIndexCuration(companyId, page = 1) {
      var url =
        "painel/metodologia/list-all-index-curation/?current_page=" +
        page +
        "&company_id=" +
        companyId +
        "&filter_understand=" +
        this.filterUnderstand +
        "&filter_type=" +
        this.filterType;

      http.get(url).then((response) => {
        this.indexCuration = response.data.results;
        this.indexCurationCount = response.data.index_curation_count;
        this.notUnderstandCount = response.data.not_understand_count;
        this.preIndexCount = response.data.pre_index_count;
        this.currentPage = response.data.current_page;
        this.totalPages = response.data.total_pages;
      });
    },
    onChangeCompany(event) {
      if (event.target.value != "") {
        this.companyId = event.target.value;
        this.getAllIndexCuration(this.companyId);
        this.filterAllCompaniesById(this.companyId);
      }
    },
    onChangeFilterUnderstand(event) {
      if (event.target.value != "") {
        this.filterUnderstand = event.target.value;
      } else {
        this.filterUnderstand = null;
      }
      this.getAllIndexCuration(this.companyId);
    },
    onChangeFilterType(event) {
      if (event.target.value != "") {
        this.filterType = event.target.value;
      } else {
        this.filterType = null;
      }
      this.getAllIndexCuration(this.companyId);
    },
    importCsv() {
      this.showLoader = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("company_id", this.companyId);
      http_file
        .post("painel/metodologia/import-intents-for-curation/", formData)
        .then((response) => {
          // this.file = null;
          this.importReturn = response.data;
          this.getAllIndexCuration(this.companyId);
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    createCuratade(){
      var payload = {
        company_id:this.companyId,
        received_text:this.curated.curatedSentence, 
        understand:this.curated.understand,
        type_relations:this.curated.type_relations,
        internal_commands:this.curated.internal_commands,
        custon_commands_id:this.curated.custon_commands,
      };
      http
        .post('painel/metodologia/creat-curated/', payload)
        .then((res) => res.data)
        .catch(() => {
            this.catch_alert.type = 'error';
            this.catch_alert.message = 'Erro ao criar a curadoria!';
            setTimeout(() => {
                $('#creatCuratedModal').modal("hide");
                this.catch_alert.type = null;
            }, 4000);
        })
        .finally(() => {
            this.catch_alert.type = 'success';
            this.catch_alert.message = 'Curadoria criada com sucesso!';
            this.getAllIndexCuration(this.companyId);
            setTimeout(() => {
                $('#creatCuratedModal').modal('hide');
                this.catch_alert.type = null;                
            }, 3000);
        });
    },
    editCuratade() {
      var payload = {
        index_curation_id: this.curated.indexCurationId,
        received_text: this.curated.curatedSentence,
        understand: this.curated.understand,
        type_relations: this.curated.type_relations,
        internal_commands: this.curated.internal_commands,
        custon_commands_id: this.curated.command_id,
      };
      http.post('painel/metodologia/edit-curated/', payload)
        .then((res) => res.data)
        .catch(() => {
          this.catch_alert.type = 'error';
          this.catch_alert.message = 'Erro ao editar a curadoria!';
          setTimeout(() => {
            $('#editCurateModal').modal('hide');
            this.catch_alert.type = null;
          }, 4000);
        })
        .finally(() => {
          this.catch_alert.type = 'success';
          this.catch_alert.message = 'Curadoria editada com sucesso!';
          this.getAllIndexCuration(this.companyId);
          setTimeout(() => {
            $('#editCurateModal').modal('hide');
            this.catch_alert.type = null;
          }, 3000);
        });
    },
    deleteCurated(){
      var payload = {
        index_curation_id: this.curated.indexCurationId,
      };
      http.post('painel/metodologia/delete-curated/', payload)
        .then((res) => res.data)
        .catch(() => {
          this.catch_alert.type = 'error';
          this.catch_alert.message = 'Erro ao deletar a curadoria!';
          setTimeout(() => {
            $('#editCurateModal').modal('hide');
            this.catch_alert.type = null;
          }, 4000);
        })
        .finally(() => {
          this.catch_alert.type = 'success';
          this.catch_alert.message = 'Curadoria deletada com sucesso!';
          this.getAllIndexCuration(this.companyId);
          setTimeout(() => {
            $('#editCurateModal').modal('hide');
            this.catch_alert.type = null;
          }, 3000);
        });

    },
    listAllComands(){
      var payload = {
        company_id: this.companyId,
      };
      http
        .post("painel/metodologia/list-all-commands-for-curation/", payload)
        .then((response) => (this.allCommands = response.data));
    },
    getCuration(curation){
      this.curated.indexCurationId = curation.id;
      this.curated.curatedSentence = curation.sentence_read;
      this.curated.understand = curation.understand;
      this.curated.type_relations = curation.type_relations;
      this.curated.internal_commands = curation.internal_commands;
      this.curated.custon_commands = curation.command_tag;
      this.curated.command_id = curation.command_id;
      this.listAllComands();
      return
    }, 
    clearTypeCuration(){
      if (this.curated.type_relations == 'internall_commands') {
        this.curated.command_id = null;
      } else {
        this.curated.internal_commands = null;
      }
    },
    filterAllCompaniesById(id) {
      this.companyName = this.allCompanies.find(
        (company) => company.id == id
      ).name;
    },
  }
};
</script>
<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Curadoria de Conteúdo</h1>
    <curated-message-cards
      :indexCurationCount="this.indexCurationCount"
      :preIndexCount="this.preIndexCount"
      :notUnderstandCount="this.notUnderstandCount"
    ></curated-message-cards>
    <div>
      <router-link to="metodologia">
        <button class="btn btn-outline-secondary btn-header">
          <i class="bi bi-arrow-left-circle"></i> Voltar
        </button>
      </router-link>
      <select
        required
        class="custom-select col-3"
        id="providerMessageTemplate"
        v-model="companyId"
        @change="onChangeCompany($event)"
      >
        <option value="" selected>SELECIONE A COMPANY</option>
        <option
          v-for="company in allCompanies"
          v-bind:key="company.id"
          :value="company.id"
        >
          {{ company.name }}
        </option>
      </select>

      <button
        type="button"
        :class="[
          'btn',
          'btn-outline-primary',
          'btn-header',
          companyId == '' ? 'disabled' : '',
        ]"
        title="Importar de um CSV"
        @click="showImportCsv = !showImportCsv"
      >
        Importar de um CSV
      </button>

      <div
        v-if="showImportCsv && companyId != ''"
        class="col-md-6 offset-md-3 container-import-csv"
      >
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="fileImportCsv"
            v-on:change="file = $event.target.files[0]"
          />
          <label class="custom-file-label" for="fileImportCsv">{{
            this.file != null ? this.file.name : "Escolher o arquivo CSV..."
          }}</label>
          <small id="passwordHelpBlock" class="form-text text-muted">
            O seu arquivo CSV deve conter as seguintes colunas:
            <b>question</b> e
            <b>command_tag</b>
          </small>
          <br />
          <div align="right">
            <button
              type="button"
              class="btn btn-secondary btn-header"
              @click="showImportCsv = false"
            >
              Cancelar
            </button>
            <button
              type="button"
              :class="[
                'btn',
                'btn-primary',
                file == null || companyId == '' ? 'disabled' : '',
              ]"
              @click="importCsv()"
            >
              Importar
            </button>
          </div>
        </div>

        <div class="container-import-result">
          <div v-if="this.showLoader" class="container-loading">
            <Loader></Loader>Carregando...
          </div>
          <div
            class="alert alert-success"
            role="alert"
            v-if="importReturn.number_of_imported_rows > 0"
          >
            <b>Quantidade de Linhas Importadas:</b>
            {{ importReturn.number_of_imported_rows }}
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            v-if="importReturn.number_of_rows_with_error > 0"
          >
            <b>Quantidade de Linhas com Erro:</b>
            {{ importReturn.number_of_rows_with_error }}
            <ul v-for="error in importReturn.cmd_with_error" v-bind:key="error">
              <li>{{ error.tag }} ( {{ error.error }} )</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Tabela de Intenções</h6>
        <div align="right">
          <select
            class="custom-select col-2 btn-header"
            id="filterUnderstand"
            @change="onChangeFilterUnderstand($event)"
            :disabled="companyId == ''"
          >
            <option value="" selected>FILTRAR POR FUNÇÃO</option>
            <option value="pre_index">Indexado</option>
            <option value="not_understand">Não Entendido</option>
            <option value="calculated_understanding">Calculado</option>
            <option value="ignore">Ignorado</option>
          </select>

          <select
            class="custom-select col-2 btn-header"
            id="filterType"
            @change="onChangeFilterType($event)"
            :disabled="companyId == ''"
          >
            <option value="" selected>FILTRAR POR TIPO</option>
            <option value="internall_commands">Comando Interno</option>
            <option value="custom_commands">Comando Personalizado</option>
          </select>

          <button
            type="button"
            class="btn btn-outline-primary"
            title="Criar Nova Intenção"
            data-toggle="modal"
            data-target="#creatCuratedModal"
            :disabled="companyId == ''"
          >
            Criar Nova Intenção
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Texto Recebido</th>
                <th>Função</th>
                <th>Tipo</th>
                <th>Comando Interno</th>
                <th>Comando Personalizado</th>
                <th>Company</th>
                <th>Editar</th>
                <th>Deletar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="curation in indexCuration" v-bind:key="curation.id">
                <td>#{{ curation.id }}</td>
                <td>{{ curation.sentence_read }}</td>
                <td>{{ curation.understand }}</td>
                <td>{{ curation.type_relations}}</td>
                <td>{{ curation.internal_commands }}</td>
                <td>{{ curation.command_tag }}</td>
                <td>{{ curation.company_name }}</td>
                <td>
                  <button type="button" class="btn btn-primary"  
                      data-bs-toggle="modal" data-bs-target="#editCurateModal" @click="getCuration(curation)">
                      <i class="fa fa-edit"></i>    
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger"
                    data-bs-toggle="modal" data-bs-target="#deleteCuratedModal" @click="getCuration(curation)" 
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <nav v-if="companyId != ''" aria-label="...">
            <ul class="pagination">
              <li
                v-if="currentPage > 1"
                class="page-item cursor-pointer"
                @click="getAllIndexCuration(companyId, currentPage - 1)"
              >
                <a class="page-link" tabindex="-1">Anterior</a>
              </li>
              <li
                v-if="currentPage > 1"
                class="page-item cursor-pointer"
                @click="getAllIndexCuration(companyId, currentPage - 1)"
              >
                <a class="page-link">{{ currentPage - 1 }}</a>
              </li>
              <li class="page-item active cursor-default">
                <a class="page-link"
                  >{{ this.currentPage }}
                  <span class="sr-only">(atual)</span></a
                >
              </li>
              <li
                v-if="currentPage < totalPages"
                class="page-item cursor-pointer"
                @click="getAllIndexCuration(companyId, currentPage + 1)"
              >
                <a class="page-link">{{ currentPage + 1 }}</a>
              </li>
              <li
                v-if="currentPage < totalPages"
                class="page-item cursor-pointer"
                @click="getAllIndexCuration(companyId, currentPage + 1)"
              >
                <a class="page-link">Próximo</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div>
    <!-- ModalCreatCurated -->
    <div class="modal fade" id="creatCuratedModal" tabindex="-1" role="dialog" aria-labelledby="creatCuratedModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-header">
                  <h5 class="modal-title" id="creatCuratedModalLabel">Nova curadoria</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                  <form>
                      <div class="form-group d-flex flex-column align-items-start">
                          <label for="recipient-id" class="col-form-label">Company: </label>
                          <input type="text" class="form-control" id="curation-id" :value="this.companyName" readonly>
                      </div><br>
                      <div>
                      </div>
                      <div class="form-group d-flex flex-column align-items-start">
                          <label for="message-text" class="col-form-label">Texto recebido: </label>
                          <textarea class="form-control" id="message-text" v-model="curated.curatedSentence" ></textarea>
                      </div>
                      <div>
                        <label for="understand" class="col_form-label">Função: </label>
                        <select
                          class="custom-select col-6 btn-header"
                          id="filterUnderstandCurated"
                          v-model="curated.understand"
                        >
                          <option value="" selected>FILTRAR POR FUNÇÃO</option>
                          <option value="pre_index">Indexado</option>
                          <option value="not_understand">Não Entendido</option>
                          <option value="calculated_understanding">Calculado</option>
                          <option value="ignore">Ignorado</option>
                        </select>
                      </div>
                      <div>
                        <label for="type_relations" class="col_form-label">Tipo: </label>
                        <select
                          class="custom-select col-6 btn-header"
                          id="filterTypeCuratad"
                          v-model="curated.type_relations"
                          @change="listAllComands()"
                        >
                          <option value="" selected>FILTRAR POR TIPO</option>
                          <option value="internall_commands">Comando Interno</option>
                          <option value="custom_commands">Comando Personalizado</option>
                        </select>
                      </div><br>
                        <div v-if="this.curated.type_relations=='internall_commands'">
                          <label for="internall_commands" class="col_form-label">Comando Interno: </label>
                          <select
                            required
                            class="custom-select col-4"
                            id="providerMessageTemplate"
                            v-model="curated.internal_commands" 
                          >
                            <option value="" selected>SELECIONE</option>
                            <option value="menu">Menu</option>
                            <option value="sair">Sair</option>
                            <option value="cursos">Cursos</option>
                            <option value="proximo">Próximo</option>
                            <option value="proxima">Próxima</option>
                            <option value="reativar">Reativar</option>
                            <option value="certificado">Certificado</option>
                            <option value="personalizado">Personalizado</option>
                            <option value="ultima">Última</option>        
                          </select>
                        </div><br>
                        <div v-if="this.curated.type_relations=='custom_commands'" >
                          <label for="command_tag" class="col_form-label">Comando Personalizado: </label>
                          <select
                            required
                            class="custom-select col-4"
                            id="providerMessageTemplate2"
                            v-model="curated.custon_commands"                            
                          >
                            <option value=""  selected>SELECIONE</option>
                            <option v-for= "command in this.allCommands" 
                              v-bind:key="command.id" :value="command.id">
                                {{ command.command_tag }}
                            </option>                          
                              
                          </select>
                        </div>
                  </form>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" class="btn btn-primary" @click="createCuratade">Criar</button>
              </div>
              <div v-if="this.catch_alert.type == 'success'">
                  
                  <div class="alert alert-success" role="alert">
                      {{ this.catch_alert.message }}
                  </div>
              </div>
              <div v-if="this.catch_alert.type == 'error'">
                  <div class="alert alert-danger" role="alert">
                      {{ this.catch_alert.message }}
                  </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Modal Edit Curate -->
    <div class="modal fade" id="editCurateModal" tabindex="-1" role="dialog" aria-labelledby="editCurateModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editCurateModalLabel">Editar Curadoria</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Fechar">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group d-flex flex-column align-items-start">
                <label for="curation-id" class="col-form-label">ID da Curadoria:</label>
                <input type="text" class="form-control" id="curation-id" :value="this.curated.indexCurationId" readonly>
              </div>
              <div class="form-group d-flex flex-column align-items-start">
                <label for="message-text" class="col-form-label">Texto recebido:</label>
                <textarea class="form-control" id="message-text" v-model="this.curated.curatedSentence"></textarea>
              </div>
              <div>
                <label for="understand" class="col_form-label">Função:</label>
                <select class="custom-select col-6 btn-header" id="filterUnderstandCurated" v-model="this.curated.understand">
                  <option value="" selected>FILTRAR POR FUNÇÃO</option>
                  <option value="pre_index">Indexado</option>
                  <option value="not_understand">Não Entendido</option>
                  <option value="calculated_understanding">Calculado</option>
                  <option value="ignore">Ignorado</option>
                </select>
              </div>
              <div>
                <label for="type_relations" class="col_form-label">Tipo:</label>
                <select class="custom-select col-6 btn-header" 
                id="filterTypeCuratad" 
                v-model="curated.type_relations" 
                @change="clearTypeCuration()"
                >
                  <option value="" selected>FILTRAR POR TIPO</option>
                  <option value="internall_commands">Comando Interno</option>
                  <option value="custom_commands">Comando Personalizado</option>
                </select>
              </div>
              <br>
              <div v-if="curated.type_relations === 'internall_commands'">
                <label for="internall_commands" class="col_form-label">Comando Interno:</label>
                <select required class="custom-select col-4" id="providerMessageTemplate" v-model="this.curated.internal_commands">
                    <option value="" selected>SELECIONE</option>
                    <option value="menu">Menu</option>
                    <option value="sair">Sair</option>
                    <option value="cursos">Cursos</option>
                    <option value="proximo">Próximo</option>
                    <option value="proxima">Próxima</option>
                    <option value="reativar">Reativar</option>
                    <option value="certificado">Certificado</option>
                    <option value="personalizado">Personalizado</option>
                    <option value="ultima">Última</option>
                </select>
              </div>
              <br>
              <div v-if="curated.type_relations === 'custom_commands'">
                <label for="command_tag" class="col_form-label">Comando Personalizado:</label>
                <select required class="custom-select col-6" id="providerMessageTemplate2" v-model="this.curated.command_id">
                  <option value="" selected>SELECIONE</option>
                  <option v-for="command in allCommands" v-bind:key="command.id" :value="command.id">                    
                    {{ command.command_tag }}
                  </option>
                </select>
                
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="editCurateModal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="editCuratade">Salvar</button>
          </div>
          <div v-if="catch_alert.type === 'success'">
            <div class="alert alert-success" role="alert">
              {{ catch_alert.message }}
            </div>
          </div>
          <div v-if="catch_alert.type === 'error'">
            <div class="alert alert-danger" role="alert">
              {{ catch_alert.message }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Delete Curation-->
    <div class="modal fade" id="deleteCuratedModal" tabindex="-1" role="dialog" aria-labelledby="deleteCuratedModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <div class="modal-body">
                  <h5>Deseja realmente deletar essa curadoria?</h5>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="deleteCuratedModal">Cancelar</button>
                  <button type="button" class="btn btn-danger" @click="deleteCurated">Deletar</button>
              </div>
              <div v-if="catch_alert.type === 'success'">
                  
                  <div class="alert alert-success" role="alert">
                      {{ catch_alert.message }}
                  </div>
              </div>
              <div v-if="catch_alert.type === 'error'">
                  <div class="alert alert-danger" role="alert">
                      {{ catch_alert.message }}
                  </div>
              </div>
          </div>
      </div>
    </div>
  

  </div>
</template>
<style scoped>
.card {
  margin-top: 1%;
}
.btn-header {
  margin: 0 10px;
}
.container-import-csv {
  margin-top: 35px;
  margin-bottom: 35px;
}
.container-import-result {
  margin-top: 35px;
}
.container-loading {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
</style>