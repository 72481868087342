<template>
  <div>
    <div class="new-message-template">
      <message-template
        v-if="isNewMessageTemplate"
        @newMessageTemplate="setNewMessageTemplate"
        :dataMessageTemplate="this.dataMessageTemplate"
      ></message-template>
    </div>

    <section class="dropdown-wrapper">
      <div @click="isVisible = !isVisible" class="selected-item">
        <span>
          {{
            selectedItem.id == "" ||
            selectedItem.id == null ||
            selectedItem.id == undefined
              ? "SELECIONE O TEMPLATE"
              : selectedItem.name
          }}</span
        >
        <i :class="['bi bi-caret-down-fill', isVisible ? 'dropdown' : '']"></i>
      </div>
      <span @click="this.addNewMessageTemplate()" class="add-item">
        <i
          :class="[
            'fs-4 mb-3 bi',
            isNewMessageTemplate
              ? 'bi-dash-circle-fill'
              : 'bi-plus-circle-fill',
          ]"
        ></i>
      </span>
      <div v-if="isVisible" class="dropdown-popover">
        <input
          v-model="searchQuery"
          class="form-control"
          type="text"
          placeholder="Buscar template..."
        />
        <div class="no-message-template">
          <span v-if="filteredTemplates.length === 0"
            >Nenhum Message Template encontrado!</span
          >
        </div>
        <div class="options">
          <ul>
            <li
              @click="selectItem(template)"
              v-for="template in filteredTemplates"
              v-bind:key="template.id"
              :class="
                template.id == this.selectedItem.id ? 'selected-template' : ''
              "
            >
              {{ template.name }}
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import MessageTemplate from "./MessageTemplate";
import { http } from "../../../http";

export default {
  props: ["templateId", "companyName"],
  components: {
    "message-template": MessageTemplate,
  },
  data() {
    return {
      searchQuery: "",
      selectedItem: {
        id: this.templateId,
        name: "",
      },
      isVisible: false,
      allTemplates: [],
      dataMessageTemplate: {
        title: null,
        tag: "#ARVORE",
      },
      isNewMessageTemplate: false,
    };
  },
  mounted() {
    this.getAllMessageTemplates();
  },
  computed: {
    filteredTemplates() {
      return this.allTemplates.filter((template) => {
        if (template.name != null) {
          return template.name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        }
      });
    },
  },
  methods: {
    getAllMessageTemplates() {
      var payload = { filter_message: "#ARVORE" };
      http
        .post("painel/messaging/read_message_template/", payload)
        .then(
          (response) => (
            (this.allTemplates = response.data), this.getNameById()
          )
        );
    },
    selectItem(template) {
      this.selectedItem.id = template.id;
      this.selectedItem.name = template.name;
      this.isVisible = false;

      this.$emit("selectedItem", this.selectedItem);
    },
    setNewMessageTemplate(data) {
      this.selectItem(data);
      this.getAllMessageTemplates();
      setTimeout(() => {
        this.isNewMessageTemplate = false;
      }, 2000);
    },
    addNewMessageTemplate() {
      this.isNewMessageTemplate = !this.isNewMessageTemplate;
      this.isVisible = false;
    },
    getNameById() {
      if (this.selectedItem.id !== "") {
        var filter = this.allTemplates.filter(
          (item) => item.id == this.selectedItem.id
        );
        if (filter.length > 0) {
          this.selectedItem.name = filter[0].name;
        }
      }
    },
  },
};
</script>
<style scoped>
.dropdown-wrapper {
  max-width: 400px;
  position: relative;
  /* margin: 0 auto; */
  display: flex;
}
.selected-item {
  width: 350px;
  /* height: 40px; */
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 500;
  padding: 5px 10px;
}
.dropdown-popover {
  position: absolute;
  top: 100%;
  left: 0;
  width: 350px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
  /* display: none; */
}
.dropdown-popover input {
  margin: 3%;
  width: 94%;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
  outline: none;
  font-weight: 200;
}
.dropdown-popover .options {
  max-height: 200px;
  padding: 0 10px;
}
.dropdown-popover .options ul {
  list-style: none;
  padding: 0 20px;
  background-color: #fcfbfb;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 10px;
}
.dropdown-popover .options ul li {
  padding: 7px 0;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}
.dropdown-popover .options ul li:last-child {
  border-bottom: none;
}
.dropdown-popover .options ul li:hover,
.dropdown-popover .options ul li.selected-template {
  background-color: #f2f2f2;
  font-weight: 500;
}
.dropdown-popover .options ul li.active {
  background-color: #f2f2f2;
  font-weight: 500;
}
.bi-caret-down-fill {
  transform: rotate(0deg);
  transition: all 0.4s ease;
}
.bi-caret-down-fill.dropdown {
  transform: rotate(180deg);
  transition: all 0.4s ease;
}
.no-message-template {
  color: #dc3545;
  text-align: center;
  margin: 10px 0;
}
.add-item {
  margin: 3px 10px;
  cursor: pointer;
}
.new-message-template {
  width: 550px;
  margin: auto;
  background-color: #fff;
}
</style>