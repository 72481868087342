import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'

import {axios} from 'axios';

import VueCookies from 'vue3-cookies'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';


createApp(App)
.use(VueCookies, {
    expireTimes: "1d",
    path: "/"
})
.use(axios)
.component('VueDatePicker', VueDatePicker)
.use(router).mount('#app')
