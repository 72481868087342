<template>
  <div>
    <!-- Begin Page Content -->
    <div class="container-fluid">
      <!-- Page Heading -->
      <h1 class="h3 mb-4 text-gray-800">Painel em Construção</h1>
      <img src="../../assets/img/pngegg.png" alt="" />
    </div>
    <!-- /.container-fluid -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Painel Interaçao",
      courses: [],
      filter: "",
    };
  },

  computed: {
    coursesWithFilter() {
      if (this.filter) {
        let exp = new RegExp(this.filter.trim(), "i");
        return this.courses.filter((foto) => exp.test(foto.name));
      } else {
        return this.courses;
      }
    },
  },

  created() {
    this.$http
      .get("http://localhost:8000/api/v1/webhook_get_course_for_painel")
      .then((res) => res.json())
      .then(
        (courses) => (this.courses = courses),
        (err) => console.log(err)
      );
  },
};
</script>
<style>
.centralizado {
  text-align: center;
}
.filter {
  display: block;
  width: 100%;
}
</style>
