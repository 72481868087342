<template>
    <div>
        <b-button
          :class="visible ? null : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          aria-controls="collapse-4"
          @click="visible = !visible"
        >
          Toggle Collapse
        </b-button>
        <b-collapse id="collapse-4" v-model="visible" class="mt-1-sm">
            <b-card header="Listagem Message Templates">
                <b-list-group v-for="template in this.list_massage_template" :key="template.id">

                  <b-list-group-item @click="">{{template.id}} --> {{template.name}}</b-list-group-item>
                  
                </b-list-group>
            </b-card>
        </b-collapse>
      </div>
</template>
<script>

export default {
    name: "lisvViewMessageTemplate",
    props: [
        "main_template_id",
        "name_company",    
    ],
    data(){
        return {
            name_teste: "teste name",
            visible: false,
            list_massage_template: {
                template1: {
                    id: 1,
                    name: "template teste 1"
                },
                template2: {
                    id: 2,
                    name: "template teste 2"
                },
                template3: {
                    id: 3,
                    name: "template teste 3"
                }
            },
        };
    },
    methods: {
        view_message_template: function(){
            var size = this.list_message_template.length()
            list_massage_template.push()
        }

    },
    mounted:{

    },
    
    
    }
</script>
<style>

</style>
