<script>
import ListMessageTemplate from "../shared/message-template/ListMessageTemplate.vue";

export default {
  components: {
    "list-message-template": ListMessageTemplate,
  },
  props: ["dataModal"],
  data() {
    return {
      showModal: false,
      modalType: "",
      showAlert: false,
      alertMessage: "",
    };
  },
  methods: {
    show(mType) {
      this.modalType = mType;
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.showAlert = false;
    },
    save() {
      if (this.dataModal.tag === "" && this.modalType !== "delete") {
        this.showAlert = true;
        this.alertMessage = "O campo tag é obrigatório!";
        return;
      }
      if (this.dataModal.order === "" && this.modalType !== "delete") {
        this.showAlert = true;
        this.alertMessage = "O campo ordem é obrigatório!";
        return;
      }
      this.showModal = false;
      this.showAlert = false;
      this.$emit("name", {
        name: this.dataModal.name,
        tag: this.dataModal.tag,
        message: this.dataModal.message,
        order: this.dataModal.order,
      });
    },
    selectedItem(item) {
      this.dataModal.message = item.id;
    },
  },
};
</script>

<template>
  <div id="modal" v-if="showModal">
    <div id="demo-modal" class="modal-tree">
      <div class="content-modal">
        <h3 class="title-modal">
          {{
            this.modalType == "add"
              ? "Adicionar um novo item"
              : this.modalType == "edit"
              ? "Editar o item: " + this.dataModal.tag
              : this.dataModal.numberOfChildren == 0
              ? "Deletar o item: " + this.dataModal.tag + "?"
              : "O item " +
                this.dataModal.tag +
                " possui filhos e NÃO pode ser deletado!"
          }}
        </h3>

        <div v-if="this.showAlert" class="alert alert-danger" role="alert">
          {{ this.alertMessage }}
        </div>

        <div v-if="this.modalType != 'delete'" class="form-fields">
          <div class="form-group">
            <label for="recipient-tag" class="col-form-label">Tag:</label>
            <input
              type="text"
              placeholder="Tag"
              class="form-control"
              id="recipient-tag"
              v-model="this.dataModal.tag"
            />
          </div>
          <div class="form-group">
            <label for="recipient-message" class="col-form-label"
              >Message Template:</label
            >
            <list-message-template
              :templateId="this.dataModal.message"
              @selectedItem="selectedItem"
            ></list-message-template>
          </div>
          <div v-if="this.dataModal.is_main == false" class="form-group">
            <label for="recipient-order" class="col-form-label">Ordem:</label>
            <input
              min="1"
              max="100"
              type="number"
              id="recipient-order"
              class="form-control"
              v-model="this.dataModal.order"
            />
          </div>
          <div v-if="this.modalType === 'edit'" class="form-group">
            <label for="recipient-name" class="col-form-label">Nome:</label>
            <input
              type="text"
              placeholder="Nome"
              class="form-control"
              id="recipient-name"
              v-model="this.dataModal.name"
              readonly
            />
          </div>
        </div>

        <div class="modal-footer">
          <button @click="hide" class="btn btn-modal btn-secondary">
            Fechar
          </button>
          <button
            v-if="
              this.dataModal.numberOfChildren == 0 ||
              this.modalType !== 'delete'
            "
            @click="save"
            type="button"
            :class="[
              'btn btn-modal',
              this.modalType == 'delete' ? 'btn-danger' : 'btn-primary',
            ]"
          >
            {{ this.modalType === "delete" ? "Deletar" : "Salvar" }}
          </button>
        </div>

        <a @click="hide" class="close-modal">&times;</a>
      </div>
    </div>
  </div>
</template>

<style>
.modal-tree {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s;
  z-index: 100;
}

.content-modal {
  border-radius: 4px;
  position: relative;
  /* width: 500px; */
  max-width: 90%;
  background: #f0f0f0;
  padding: 1em 1em;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #585858;
  text-decoration: none;
  cursor: pointer;
}

.btn-modal {
  margin: 0 5px;
}

.title-modal {
  color: black;
  width: 500px;
}
</style>