<script>
import { http } from "../../http";

export default {
  data() {
    return {
      allPushBatches: null,
      clients: [],
      projects: [],
      filterClient: null,
      filterProject: null,
      currentPage: 1,
    };
  },


  mounted() {
    this.getClients();
  },

  
  methods: {
    formatDate(date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate().toString().padStart(2, "0");
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const year = dateObj.getFullYear();
      const hours = dateObj.getHours().toString().padStart(2, "0");
      const minutes = dateObj.getMinutes().toString().padStart(2, "0");
      const seconds = dateObj.getSeconds().toString().padStart(2, "0");
      if (this.allEnrollments) {
        this.number_enroll = this.allEnrollments.length;
      } else {
        this.number_enroll = 0;
      }

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
      
    },
    getAllPushBatches() {
      var payload = {
        client_id: this.filterClient,
      };

      http
        .post("painel/atend_comercial/report_push_batchs/", payload)
        .then((response) => {
          this.allPushBatches = response.data;
          this.projects = this.listProjects();

          if (this.filterProject) {
            this.allPushBatches = this.allPushBatches.filter((batch) => {
              return batch.project_name === this.filterProject;
            });
          }
        });
    },

    onChangeFilterClient() {
      this.allPushBatches = null;
      this.projects = [];
      this.filterProject = null;

      this.filterClient = document.getElementById("selectFilterClient").value;

      if (this.filterClient) {
        this.getAllPushBatches();
      }
    },

    onChangeFilterProject() {
      this.allPushBatches = null;

      this.filterClient = document.getElementById("selectFilterClient").value;
      this.filterProject = document.getElementById("selectFilterProject").value;

      if (this.filterClient) {
        this.getAllPushBatches();
      }
    },

    getClients: function () {
      var payload = {
        current_page: this.currentPage,
      };
      http
        .post("read_client/", payload)
        .then((res) => (this.clients = res.data));
    },

    downloadFile: function (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },

    goToPage: function (id) {
      window.open("/#/push-batch/" + id, "_blank");
    },

    listProjects: function () {
      const uniqueProjects = this.allPushBatches
        .map((project) => project.project_name)
        .filter((project, index, array) => array.indexOf(project) === index);

      return uniqueProjects;
    },
  },
};
</script>

<template>
  <div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Informações dos Push Batches</h1>

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">
          Informações dos Push Batches por Cliente
        </h6>
        <div class="d-flex align-items-center justify-content-end">
          <label class="list-clients" for="selectFilterClient"
            ><b>SELECIONE UM CLIENTE: </b></label
          >
          <select
            class="custom-select col-2 btn-header"
            id="selectFilterClient"
            @change="onChangeFilterClient"
          >
            <option value="" selected>-- CLIENTES --</option>
            <option
              v-for="client in clients"
              :value="client.id"
              :key="client.id"
            >
              {{ client.name }}
            </option>
          </select>

          <select
            class="custom-select col-2 btn-header"
            id="selectFilterProject"
            @change="onChangeFilterProject"
          >
            <option value="" selected>TODOS OS PROJETOS</option>
            <option v-for="project in projects" :value="project" :key="project">
              {{ project }}
            </option>
          </select>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table
            class="table table-bordered"
            id="dataTable"
            width="100%"
            cellspacing="0"
            selectedClass="table-info"
          >
            <thead>
              <tr>
                <th>Nome</th>
                <th>Sender</th>
                <th>Criado por</th>
                <th>Projeto</th>
                <th>Total de Números</th>
                <th>Linhas Processadas</th>
                <th>Quantidade de Falha</th>
                <th>Modified</th>
                <th>Arquivo</th>
                <th>Visualizar</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in allPushBatches" v-bind:key="data.id">
                <td>{{ data.identifier }}</td>
                <td>{{ data.sender_phone_number }}</td>
                <td>{{ data.created_by_email }}</td>
                <td>{{ data.project_name }}</td>
                <td>{{ data.count_lines }}</td>
                <td>{{ data.processed }}</td>
                <td>{{ data.failed }}</td>
                <td>{{ formatDate(data.modified) }}</td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      v-if="data.file_url != null"
                      class="bi bi-filetype-csv text-primary button-action"
                      @click="downloadFile(data.file_url)"
                    ></i>
                    <i v-else class="bi bi-x-circle-fill text-danger"></i>
                  </div>
                </td>
                <td>
                  <div class="fs-4 mb-3">
                    <i
                      class="bi bi-eye text-primary button-action"
                      @click="goToPage(data.id)"
                    ></i>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!this.filterClient" class="container-loading">
            <i class="bi bi-exclamation-triangle-fill">
              SELECIONE UM CLIENTE
            </i>
          </div>
          <div
            v-else-if="!this.allPushBatches && this.filterClient"
            class="container-loading"
          >
            <i class="bi bi-hourglass-split">Carregando...</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-header {
  margin: 0 10px;
}
.button-action {
  cursor: pointer;
}
.link-action {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.list-clients {
  margin-top: 10px;
}
</style>
