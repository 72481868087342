<template>
<div v-if="local_view_status == true">
    <div :class="['alert alert-dismissible show p-2', local_type_alert == 'success' ? 'alert-success' : local_type_alert == 'warning' ? 'alert-warning' : loca_type_alert == 'danger' ? 'alert-danger' : 'alert-secondary']" role="alert">
        <button v-if="local_name_first_button == null & local_name_second_button == null"   v-on:click="$emit('cloaseAlert', false)" type="button" class="btn-close close-alert-default" data-bs-dismiss="alert" aria-label="Close"></button>

        <div class="content-father-flex">
            <div>
                {{this.local_message_alert}}
            </div>
            
            <div class="content-child-flex" style="gap: 20px;">
                <button type="button" class="btn btn-secondary" v-on:click="$emit('cloaseAlert', false)" v-if="local_name_first_button">{{this.local_name_first_button}}</button>

                <button type="button" class="btn btn-danger" 
                    v-if="local_name_second_button" 
                    v-on:click="$emit('statusUpdate',local_status)">{{this.name_second_button}}
                </button>                
            </div>
        </div>
    </div>
   
</div>
</template>

<script>
export default {
    props: ["message_alert", "type_alert", "type_confimation", "name_first_button", "name_second_button", "view_status"],
    emits: ['statusUpdate'],
    updated() {
        this.local_message_alert = this.message_alert,
        this.local_type_alert = this.type_alert,
        this.local_name_first_button = this.name_first_button,
        this.local_name_second_button = this.name_second_button
        this.local_view_status = this.view_status
        this.local_type_confimation = this.type_confimation
        this.local_status.type = this.type_confimation
        this.local_status.view = this.local_view_status
    },

    data() {
        return {
            local_message_alert: null,
            local_type_alert: null,
            local_name_first_button: null,
            local_name_second_button: null,
            local_view_status: null,
            local_type_confimation: null,
            local_status:{
                type: null,
                view: null
            }
        };
    },

    methods: {


    },

}
</script>

<style>
    .close-alert-default {
        display: flex;
        align-items: center;
        padding: 0.5vmax !important;
        justify-content: center;
    }
</style>
