<script>
import Modal from "./Modal.vue";
import { http } from "../../http";

export default {
  name: "TreeItem", // necessary for self-reference
  components: { modal: Modal },
  props: {
    model: Object,
  },
  data() {
    return {
      isOpen: true,
      modalAction: "",
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    },
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    addNewChildren() {
      if (!this.isFolder) {
        this.model.children = [];
        this.addChild();
        this.isOpen = true;
      }
    },
    addChild() {
      this.modalAction = "add";
      this.$refs.modal.show(this.modalAction);
    },
    removeLastItem() {
      this.modalAction = "delete";
      this.$refs.modal.show(this.modalAction);
    },
    reloadPage() {
      location.reload();
    },
    changeType() {
      this.modalAction = "edit";
      this.$refs.modal.show(this.modalAction);
    },
    dataModal: function () {
      if (this.modalAction === "add") {
        return {
          name: "",
          tag: "",
          message: "",
          order: 1,
          is_main: false,
        };
      } else if (this.modalAction === "edit") {
        return {
          name: this.model.name,
          tag: this.model.tag,
          message: this.model.message,
          order: this.model.order,
          is_main: this.model.is_main,
        };
      } else if (this.modalAction === "delete") {
        if (this.model.children.length > 0) {
          return {
            tag: this.model.children[this.model.children.length - 1].tag,
            numberOfChildren:
              this.model.children[this.model.children.length - 1].children
                .length,
          };
        } else {
          return {
            tag: this.model.tag,
            numberOfChildren: this.model.children.length,
          };
        }
      }
    },
    getName(value) {
      // TODO: Mudar o getName para um nome mais significativo.
      if (this.modalAction === "add") {
        var data = null;
        // TODO: Verificar a possibilidade de fazer um Refactor neste código duplicado.
        var payload = {
          name: value.tag,
          tag: value.tag,
          message: value.message,
          order_id: value.order,
          is_main: false,
          parent_command_id: this.model.id,
        };

        http.post("painel/metodologia/save-command-tree/", payload).then(
          (response) => (
            (data = response.data),
            this.model.children.push(
              {
                id: data.id,
                name: data.name,
                tag: value.tag,
                message: value.message,
                order: value.order,
                is_main: false,
                children: [],
              },
              this.reloadPage()
            )
          )
        );
      } else if (this.modalAction === "edit") {
        var new_name = "#" + value.tag + this.model.id;
        var payload = {
          id: this.model.id,
          name: new_name,
          tag: value.tag,
          message: value.message,
          order_id: value.order,
          is_main: this.model.is_main,
        };

        http
          .post("painel/metodologia/edit-command-tree/", payload)
          .then(
            (response) => (
              (data = response.data),
              (this.model.name = new_name),
              (this.model.tag = value.tag),
              (this.model.message = value.message),
              (this.model.order = value.order),
              this.reloadPage()
            )
          );
      } else if (this.modalAction === "delete") {
        var payload = {
          id: this.model.id,
          delete_type: "command",
        };

        http
          .post("painel/metodologia/delete-command-tree/", payload)
          .then((response) => ((data = response.data), this.reloadPage()));
      }
    },
  },
};
</script>

<template>
  <div>
    <li>
      <div>
        <span :class="{ 'bold-items': isFolder }" @dblclick="changeType">
          {{ model.is_main ? "" : model.order + " - " }} {{ model.tag }}
        </span>
        &nbsp;

        <span v-if="!model.is_main && !isFolder && isOpen">
          <i @click="removeLastItem" class="bi bi-trash"></i>
          &nbsp;
        </span>

        <i
          v-if="isFolder"
          @click="toggle"
          :class="[
            'bi',
            isOpen ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand',
          ]"
        ></i>
        <i
          v-if="!isFolder"
          @click="addNewChildren"
          class="bi bi-plus-circle"
        ></i>
      </div>
      <ul v-show="isOpen" v-if="isFolder" class="list-items">
        <TreeItem
          class="item"
          v-for="model in model.children"
          :model="model"
          v-bind:key="model"
        >
        </TreeItem>
        <li>
          <i @click="addChild" class="bi bi-plus-circle"></i>
        </li>
      </ul>
    </li>
    <modal @name="getName" ref="modal" :dataModal="dataModal()"></modal>
  </div>
</template>

<style scoped>
.list-items {
  margin-left: 50px;
}
.bold-items {
  font-weight: bold;
}
</style>