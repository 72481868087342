<template>
  <div class="container-curated-message-cards">
    <div class="row">
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                >
                  Total de Intenções
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ indexCurationCount }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="primary"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Intenções Indexadas
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ preIndexCount }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="success"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-danger shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2 ml-1">
                <div
                  class="text-xs font-weight-bold text-danger text-uppercase mb-1"
                >
                  Mensagens não Intendidas
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ notUnderstandCount }}
                </div>
              </div>
              <div class="col-auto">
                <div class="col-auto">
                  <b-icon
                    icon="exclamation-circle-fill"
                    font-scale="2"
                    variant="danger"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    indexCurationCount: {
      type: Number,
      default: 0,
    },
    preIndexCount: {
      type: Number,
      default: 0,
    },
    notUnderstandCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style>
.container-curated-message-cards {
  margin-bottom: 30px;
}
</style>
