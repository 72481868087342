<template>
<div class="background-login">

    <!-- Before Loader -->:
    <div class="container-fluid">
        <main class="form-signin">
            <form class="mt-4" style="width: 40% !important">
                <div class="d-flex justify-content-center w-100">
                    <img class="mb-2" src="https://www.lys.academy/site/wp-content/uploads/2021/06/cropped-logo_lys.png" style="width: 80% !important;">
                </div><br>

                <div class="form-floating">
                    <span class="badge text-bg-primary fs-6" for="floatingInput">Email</span>
                    <input v-model="email" v-on:keyup.enter="Request_login('teste paramentro')" type="email" class="form-control input-padrao" id="floatingInput" />
                </div><br>

                <div class="form-floating">
                    <span class="badge text-bg-primary fs-6" for="floatingPassword">Senha</span>
                    <input v-model="password" v-on:keyup.enter="Request_login('teste paramentro')" type="password" class="form-control input-padrao" id="floatingPassword" />
                </div>

                <div class="d-flex justify-content-center w-100">
                    <button class="btn btn-primary mt-3 col-8 rounded-pill fs-5 fw-bold" v-on:click="Request_login('teste paramentro')">
                        Entrar
                    </button>
                </div>

                <div class="w-100 checkbox mb-3 d-flex justify-content-center">
                    <label>
                        <div v-if="loader">
                            <Loader></Loader>
                        </div>
                    </label>
                </div>
            </form>
        </main>
    </div>
</div>
</template>

<script>
import { http, headers } from "../../http";
import { useCookies } from "vue3-cookies"
import Loader from "../shared/painel/Loader.vue";

export default {
    data() {
        return {
            email: "",
            password: "",
            token: "",
            title: "Login Lys",
            loader: false,
            erroLogin: false,
        };
    },

    components: {
        Loader
    },

    methods: {
        Request_login: function (parametro) {
            this.loader = true;
            this.erroLogin = false;
            console.log("dentro da request LOGIN")

            var payload = {
                email: this.email,
                password: this.password,
            };

            http
                .post("login", payload)
                .then((response) => {
                    console.log(response);

                    this.token = response.data.access_token;
                    console.log("O TOKEN É ", response)
                    this.loader = false;
                    this.erroLogin = false;
                    this.$cookies.set("user_session", this.token);
                    window.location.reload();
                })
                .catch((error) => {
                    console.log("error login", error);
                    this.loader = false;
                    this.erroLogin = true;
                    this.$cookies.set("user_session", "");
                });
        },
    },
};
</script>

<style>
.centralizado {
    text-align: center;
}

.filter {
    display: block;
    width: 100%;
}

#img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 150px, 150px, 150px, 150px;
}

#container-menu {
    /*margin-top: 5%;*/
    display: center;
    justify-content: left;
}

.div-main {
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 50rem;
    /*background-color: #f5f5f5;*/
}

.form-signin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    /*max-width: 330px;*/
    padding: 15px;
    margin-top: 5%;
    /*margin: auto;*/
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 20;
    border-bottom-left-radius: 9;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.background-login {
    width: 100%;
    height: 100vh;
    display: flex;
    background-image: url("../../assets/img/banner-login.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.badge-login {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 1em;
}

.input-padrao {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    outline: none !important;
    border: 1px solid #018cc8;
    max-height: 2em;
    padding: 1% 5% !important;
    box-shadow: 4px 4px #018cc895;
}

.input-padrao:focus {
    outline: none !important;
    border: 1px solid #018cc8 !important;
    box-shadow: 4px 4px #018cc895 !important;
}

#loader {
    margin-left: 0 !important;
}

html {
    overflow-y: hidden;
}
</style>
