<template>
  <div class="col-xl-3 col-md-6 mb-4">
    <div
      :class="[
        'card',
        'shadow',
        'h-100',
        color == 'primary'
          ? 'border-left-primary'
          : color == 'success'
          ? 'border-left-success'
          : color == 'info'
          ? 'border-left-info'
          : color == 'warning'
          ? 'border-left-warning'
          : color == 'danger'
          ? 'border-left-danger'
          : 'border-left-info',
      ]"
    >
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div
              :class="[
                'text-xs',
                'font-weight-bold',
                'text-uppercase',
                'mb-1',
                color == 'primary'
                  ? 'text-primary'
                  : color == 'success'
                  ? 'text-success'
                  : color == 'info'
                  ? 'text-info'
                  : color == 'warning'
                  ? 'text-warning'
                  : color == 'danger'
                  ? 'text-danger'
                  : 'text-gray-900',
              ]"
            >
              {{ nameCard }}
            </div>
            <div class="h5 mb-0 font-weight-bold text-gray-800" align="right">
              {{ amount }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["nameCard", "amount", "color"],
};
</script>
<style></style>